<template>
  <div>
    <a-form :form="form" v-bind="formLayout">
      <a-row :gutter="[16,16]">
        <a-col :span="12">
          <a-form-item label="条形码">
            <a-input v-decorator="['barcode', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="海关编码" >
            <a-input v-decorator="['hs_code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:12, message: '输入超出长度限制'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="售卖单位" >
            <a-select
              v-decorator="[
                'unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="法定单位" >
            <a-select
              v-decorator="[
                'legal_unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="法定单位2" >
            <a-select
              v-decorator="[
                'legal_unit2',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="售卖单位与法定单位的比例" >
            <a-input
              v-decorator="['rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="售卖单位与法定第二单位的比例" >
            <a-input
              v-decorator="['rate2', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="每单位净重(千克)" >
            <a-input
              v-decorator="['net_weight', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="每单位毛重(千克)" >
            <a-input
              v-decorator="['gross_weight', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="品牌、规格、型号" >
            <a-input
              v-decorator="['model', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="原产地" >
            <a-select
              v-decorator="[
                'original_place',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
              show-search
              option-filter-prop="children"
              :filter-option="filterOption"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.country">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="false">
          <a-form-item label="关税税率" >
            <a-input
              addon-after="%"
              v-decorator="['tariff_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="增值税" >
            <a-input
              addon-after="%"
              v-decorator="['value_added_tax_rate', {initialValue: 0, rules: [{required: true, message: '必填项，请填写信息'}]}]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="开启消费税" >
            <a-switch v-decorator="['open_consumption_tax', {initialValue: false, valuePropName: 'checked'}]" checked-children="开" un-checked-children="关" @change="openConsumptionTax"/>
          </a-form-item>
        </a-col>
        <a-col :span="12" v-if="open_consumption_tax === 2">
          <a-form-item label="消费税单位">
            <a-select
              v-decorator="[
                'consumption_tax_unit',
                {
                  rules: [
                    { required: true, message: '请选择' },
                  ],
                },
              ]"
              placeholder="选择"
            >
              <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.consumption_tax_unit">{{ val }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col span="24" style="text-align: center">
          <a-button style="margin-right: 40px;" type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
          <a-button style="margin-left: 40px;" @click="handleGoBack">返回</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import pick from 'lodash.pick'
import { goods_declaration_update } from '@/api/c_wms_goods'
// 表单字段
const fields = ['id', 'barcode', 'hs_code', 'unit', 'legal_unit', 'legal_unit2', 'hs_code', 'rate', 'rate2', 'net_weight', 'gross_weight',
  'model', 'original_place', 'consumption_tax_rate', 'value_added_tax_rate', 'open_consumption_tax', 'consumption_tax_unit'
]
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 9 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      form: this.$form.createForm(this),
      id: 0,
      open_consumption_tax: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.loadEditInfo(this.record)
    })
  },
  methods: {
    handleGoBack () {
      this.$emit('onGoBack')
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (err) {
          console.log(values, err)
          return
        }
        values.open_consumption_tax = this.open_consumption_tax
        goods_declaration_update(values, this.id)
          .then((res) => {
            this.handleGoBack()
    })
      })
    },
    loadEditInfo (data) {
      const { form } = this
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        this.open_consumption_tax = data.open_consumption_tax
        data.open_consumption_tax = data.open_consumption_tax === 2
        data.consumption_tax_unit = data.consumption_tax_unit + ''
        const formData = pick(data, fields)
        this.id = formData.id
        form.setFieldsValue(formData)
      })
    },
    openConsumptionTax (value) {
      this.open_consumption_tax = value ? 2 : 1
      console.log(this.open_consumption_tax)
    }
  }
}
</script>
